const tableHeader = [
    "First Name",
    "Last Name",
    "Account No.",
    "Meter No.",
    "Tarrif",
    "Previous Reading",
    "Present Reading",
    "Consumption",
    "Dss Name",
    "Feeder Name",
    "image",
    "HUB",
    "Region",
    "Service Center",
    "Customer Type",
    "Comment",
    "CreatedBy",
    "Status",
    "Date Created",
    "Read Date:",
    "Action"
];

const tableRows = [
    {
        firstName: "Johnson",
        lastName: "Adekunle",
        accountNo: "05126688274",
        meterNo: "0100028734",
        tarrif: "Witchy Woman",
        previousReading: "The Eagles",
        presentReading: "1972",
        consumption: "Service Center",
        dssName: "Prepaid",
        feederName: "Phone",
        image: "Account No.",
        hub: "Ibadan",
        region: "Meter No.",
        serviceCenter: "Eleyele",
        customerType: "Customer Type",
        comment: "MD",
        createdBy: "...",
        status: "Active",
        dateCreated: "0806514210",
        readDate: "View More",
        action: "View More"

    },
    {
        firstName: "Johnson",
        lastName: "Adekunle",
        accountNo: "05126688274",
        meterNo: "0100028734",
        tarrif: "Witchy Woman",
        previousReading: "The Eagles",
        presentReading: "1972",
        consumption: "Service Center",
        dssName: "Prepaid",
        feederName: "Phone",
        image: "Account No.",
        hub: "Ibadan",
        region: "Meter No.",
        serviceCenter: "Eleyele",
        customerType: "Customer Type",
        comment: "MD",
        createdBy: "...",
        status: "Active",
        dateCreated: "0806514210",
        readDate: "View More",
        action: "View More"
    },
    {
        firstName: "Johnson",
        lastName: "Adekunle",
        accountNo: "05126688274",
        meterNo: "0100028734",
        tarrif: "Witchy Woman",
        previousReading: "The Eagles",
        presentReading: "1972",
        consumption: "Service Center",
        dssName: "Prepaid",
        feederName: "Phone",
        image: "Account No.",
        hub: "Ibadan",
        region: "Meter No.",
        serviceCenter: "Eleyele",
        customerType: "Customer Type",
        comment: "MD",
        createdBy: "...",
        status: "Active",
        dateCreated: "0806514210",
        readDate: "View More",
        action: "View More"
    },
    {
        firstName: "Johnson",
        lastName: "Adekunle",
        accountNo: "05126688274",
        meterNo: "0100028734",
        tarrif: "Witchy Woman",
        previousReading: "The Eagles",
        presentReading: "1972",
        consumption: "Service Center",
        dssName: "Prepaid",
        feederName: "Phone",
        image: "Account No.",
        hub: "Ibadan",
        region: "Meter No.",
        serviceCenter: "Eleyele",
        customerType: "Customer Type",
        comment: "MD",
        createdBy: "...",
        status: "Active",
        dateCreated: "0806514210",
        readDate: "View More",
        action: "View More"
    },
    {
        firstName: "Johnson",
        lastName: "Adekunle",
        accountNo: "05126688274",
        meterNo: "0100028734",
        tarrif: "Witchy Woman",
        previousReading: "The Eagles",
        presentReading: "1972",
        consumption: "Service Center",
        dssName: "Prepaid",
        feederName: "Phone",
        image: "Account No.",
        hub: "Ibadan",
        region: "Meter No.",
        serviceCenter: "Eleyele",
        customerType: "Customer Type",
        comment: "MD",
        createdBy: "...",
        status: "Active",
        dateCreated: "0806514210",
        readDate: "View More",
        action: "View More"
    },

    {
        "firstName": "John",
        "lastName": "Doe",
        "accountNo": "AC001",
        "meterNo": "MTR001",
        "tarrif": "Tarrif A",
        "previousReading": "100",
        "presentReading": "200",
        "consumption": "100",
        "dssName": "DSS 1",
        "feederName": "Feeder 1",
        "image": "image1.jpg",
        "hub": "Hub 1",
        "region": "Region 1",
        "serviceCenter": "Service Center 1",
        "customerType": "Residential",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },

    {
        "firstName": "Jane",
        "lastName": "Smith",
        "accountNo": "AC002",
        "meterNo": "MTR002",
        "tarrif": "Tarrif B",
        "previousReading": "150",
        "presentReading": "300",
        "consumption": "150",
        "dssName": "DSS 2",
        "feederName": "Feeder 2",
        "image": "image2.jpg",
        "hub": "Hub 2",
        "region": "Region 2",
        "serviceCenter": "Service Center 2",
        "customerType": "Commercial",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },

    {
        "firstName": "Michael",
        "lastName": "Johnson",
        "accountNo": "AC003",
        "meterNo": "MTR003",
        "tarrif": "Tarrif C",
        "previousReading": "200",
        "presentReading": "400",
        "consumption": "200",
        "dssName": "DSS 3",
        "feederName": "Feeder 3",
        "image": "image3.jpg",
        "hub": "Hub 3",
        "region": "Region 3",
        "serviceCenter": "Service Center 3",
        "customerType": "Industrial",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },

    {
        "firstName": "Emily",
        "lastName": "Davis",
        "accountNo": "AC004",
        "meterNo": "MTR004",
        "tarrif": "Tarrif D",
        "previousReading": "120",
        "presentReading": "250",
        "consumption": "130",
        "dssName": "DSS 4",
        "feederName": "Feeder 4",
        "image": "image4.jpg",
        "hub": "Hub 4",
        "region": "Region 4",
        "serviceCenter": "Service Center 4",
        "customerType": "Residential",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },

    {
        "firstName": "David",
        "lastName": "Martinez",
        "accountNo": "AC005",
        "meterNo": "MTR005",
        "tarrif": "Tarrif E",
        "previousReading": "180",
        "presentReading": "300",
        "consumption": "120",
        "dssName": "DSS 5",
        "feederName": "Feeder 5",
        "image": "image5.jpg",
        "hub": "Hub 5",
        "region": "Region 5",
        "serviceCenter": "Service Center 5",
        "customerType": "Commercial",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },

    {
        "firstName": "Sophia",
        "lastName": "Johnson",
        "accountNo": "AC006",
        "meterNo": "MTR006",
        "tarrif": "Tarrif F",
        "previousReading": "220",
        "presentReading": "400",
        "consumption": "180",
        "dssName": "DSS 6",
        "feederName": "Feeder 6",
        "image": "image6.jpg",
        "hub": "Hub 6",
        "region": "Region 6",
        "serviceCenter": "Service Center 6",
        "customerType": "Industrial",
        "comment": "No comments",
        "createdBy": "Admin",
        "status": "Active",
        "dateCreated": "2024-03-19",
        "readDate": "2024-03-18",
        "action": "View more"
    },
    { "firstName": "Anaïs", "last_name": "Glowacki", "accountNo": "5f14406c6b20f28f259909f47bcf6f96f786de4fbf1193c7d0fd5c1f89a94090", "meterNo": "b225750ab0532d455408b9e31d62524c", "tarrif": 33, "previousReading": 24, "presentReading": 7, "consumption": 87, "dssName": "Al Bilād", "feederName": null, "image": "http://dummyimage.com/102x100.png/5fa2dd/ffffff", "hub": "Novotroitsk", "region": "Kemang", "serviceCenter": "Shoshone", "customerType": "YE", "comment": "Customer cooperated during Enumeration", "createdBy": "Liva Glowacki", "status": "Sucessful, failed, pending", "dateCreated": "5/15/2023", "readDate": "9/7/2023", "action": "8/17/2023" },
    { "firstName": "Desirée", "last_name": "Petrelli", "accountNo": "fa5f1408a43a22f0397c1f1f41ac0ed8ea041aaa63d8aae42f142182780e605a", "meterNo": "bf30d6b84367e06ecf13de35de8ee53c", "tarrif": 38, "previousReading": 73, "presentReading": 38, "consumption": 82, "dssName": "May Pen", "feederName": null, "image": "http://dummyimage.com/209x100.png/dddddd/000000", "hub": "Ol Kalou", "region": "Mboto", "serviceCenter": "Summerview", "customerType": "JM", "comment": "Customer cooperated during Enumeration", "createdBy": "Floris Petrelli", "status": "Sucessful, failed, pending", "dateCreated": "9/16/2023", "readDate": "12/18/2023", "action": "9/29/2023" },
    { "firstName": "Céline", "last_name": "Coard", "accountNo": "b827a492208b53bd89a036d158795300f44a841976b4410dc4d3c303aa332703", "meterNo": "80937150a9d435e64ba7eea9552d2551", "tarrif": 67, "previousReading": 13, "presentReading": 26, "consumption": 46, "dssName": "Rasskazovo", "feederName": "680001", "image": "http://dummyimage.com/155x100.png/cc0000/ffffff", "hub": "Chornyanka", "region": "Qingguang", "serviceCenter": "Transport", "customerType": "RU", "comment": "Customer cooperated during Enumeration", "createdBy": "Nicky Coard", "status": "Sucessful, failed, pending", "dateCreated": "11/1/2023", "readDate": "12/20/2023", "action": "9/20/2023" },
    { "firstName": "Noémie", "last_name": "McConnachie", "accountNo": "1ece216a02b5e62ad340fc5feb1d6ad6607f5906bec21de046c51f9ffa2c6a55", "meterNo": "34e28ed66923a35bc4cdd0618866c36b", "tarrif": 70, "previousReading": 73, "presentReading": 78, "consumption": 63, "dssName": "Pensilvania", "feederName": "173069", "image": "http://dummyimage.com/151x100.png/5fa2dd/ffffff", "hub": "Aizkraukle", "region": "Paris 08", "serviceCenter": "Gale", "customerType": "CO", "comment": "Customer cooperated during Enumeration", "createdBy": "Bondie McConnachie", "status": "Sucessful, failed, pending", "dateCreated": "2/15/2024", "readDate": "1/30/2024", "action": "8/29/2023" },
    { "firstName": "Laïla", "last_name": "Urpeth", "accountNo": "697371460f9546f7d858433faa64c1bbc7f42debb462ae678d439911745f9575", "meterNo": "30a72faebbe61b6c255e4a7dd3be008f", "tarrif": 96, "previousReading": 4, "presentReading": 78, "consumption": 49, "dssName": "Sarulla", "feederName": null, "image": "http://dummyimage.com/119x100.png/dddddd/000000", "hub": "Santa Rita", "region": "Tonggakjati", "serviceCenter": "Wayridge", "customerType": "ID", "comment": "Customer cooperated during Enumeration", "createdBy": "Germaine Urpeth", "status": "Sucessful, failed, pending", "dateCreated": "10/13/2023", "readDate": "6/23/2023", "action": "10/10/2023" },
    { "firstName": "Yóu", "last_name": "Seddon", "accountNo": "c24ef3972a50fb28343071ece017fe2d9574cf2745327ac40719ecc6a3be8e3f", "meterNo": "559183e4b09e4bc8305b90f6ed37015d", "tarrif": 36, "previousReading": 71, "presentReading": 63, "consumption": 81, "dssName": "Huaizhong", "feederName": null, "image": "http://dummyimage.com/214x100.png/5fa2dd/ffffff", "hub": "Russkiy", "region": "Sakai", "serviceCenter": "Paget", "customerType": "CN", "comment": "Customer cooperated during Enumeration", "createdBy": "Sampson Seddon", "status": "Sucessful, failed, pending", "dateCreated": "12/10/2023", "readDate": "3/8/2024", "action": "6/20/2023" },
    { "firstName": "Géraldine", "last_name": "Curnow", "accountNo": "e1e1c9630a7ed1ca82850032d0807e2313082c1cb899707782226afef4f140e7", "meterNo": "b6fe3c2804fd30687d81517a132ab89e", "tarrif": 21, "previousReading": 82, "presentReading": 41, "consumption": 24, "dssName": "Bago", "feederName": null, "image": "http://dummyimage.com/194x100.png/cc0000/ffffff", "hub": "Sang-e Māshah", "region": "Taipa", "serviceCenter": "Melvin", "customerType": "MM", "comment": "Customer cooperated during Enumeration", "createdBy": "Sarena Curnow", "status": "Sucessful, failed, pending", "dateCreated": "8/7/2023", "readDate": "4/22/2023", "action": "1/2/2024" },
    { "firstName": "Gérald", "last_name": "Jeacocke", "accountNo": "8b7bcf1657910033bc1322c66dc820b2fc5faee69d392b5ffa8549a2cacb21b1", "meterNo": "c673a5bf6bf1c08126db531f4cc663e5", "tarrif": 14, "previousReading": 9, "presentReading": 10, "consumption": 71, "dssName": "Šabac", "feederName": null, "image": "http://dummyimage.com/129x100.png/cc0000/ffffff", "hub": "Cam Ranh", "region": "Raciążek", "serviceCenter": "Clarendon", "customerType": "RS", "comment": "Customer cooperated during Enumeration", "createdBy": "Verna Jeacocke", "status": "Sucessful, failed, pending", "dateCreated": "9/3/2023", "readDate": "2/10/2024", "action": "2/25/2024" },
    { "firstName": "Néhémie", "last_name": "Hucquart", "accountNo": "0d458aa38c3d7ef9cd01dc82c1219ab43cdafc3d90fa8568fa8e350433ceee4e", "meterNo": "84dd8c16705a9ddeafda737cca59bd7b", "tarrif": 50, "previousReading": 32, "presentReading": 2, "consumption": 72, "dssName": "Khotiv", "feederName": null, "image": "http://dummyimage.com/177x100.png/dddddd/000000", "hub": "Jimenez", "region": "Arvidsjaur", "serviceCenter": "Ruskin", "customerType": "UA", "comment": "Customer cooperated during Enumeration", "createdBy": "Sergeant Hucquart", "status": "Sucessful, failed, pending", "dateCreated": "10/27/2023", "readDate": "5/10/2023", "action": "12/5/2023" },
    { "firstName": "Ophélie", "last_name": "Negus", "accountNo": "09e1ad9e77474e6563717d9459c0d186ece95b13f3a8967d186748095aa15d07", "meterNo": "ccf088b93feaaefd36c5b6b7288f0275", "tarrif": 84, "previousReading": 27, "presentReading": 50, "consumption": 94, "dssName": "Hedi", "feederName": null, "image": "http://dummyimage.com/225x100.png/dddddd/000000", "hub": "Pisões", "region": "Bunog", "serviceCenter": "Erie", "customerType": "CN", "comment": "Customer cooperated during Enumeration", "createdBy": "Talbert Negus", "status": "Sucessful, failed, pending", "dateCreated": "6/3/2023", "readDate": "7/31/2023", "action": "10/25/2023" },
    { "firstName": "Lucrèce", "last_name": "Maha", "accountNo": "99a48acf9b8e39ffa7dafcade9eb7f33f5a03806c7c00efe373d24e06acedcb3", "meterNo": "b751fb04143466bc689563107ca3358e", "tarrif": 22, "previousReading": 50, "presentReading": 16, "consumption": 85, "dssName": "Selaphum", "feederName": "33150", "image": "http://dummyimage.com/115x100.png/5fa2dd/ffffff", "hub": "Tambir", "region": "San Cristóbal Totonicapán", "serviceCenter": "Banding", "customerType": "TH", "comment": "Customer cooperated during Enumeration", "createdBy": "Marys Maha", "status": "Sucessful, failed, pending", "dateCreated": "4/29/2023", "readDate": "7/1/2023", "action": "12/30/2023" },
    { "firstName": "Örjan", "last_name": "Canny", "accountNo": "1e2a2310937363e3de9d1331148cfc1e298bf596ab04a5da4d5085ea443ef053", "meterNo": "b2f5a5a59f1e8737a32aeefbc38f6c11", "tarrif": 17, "previousReading": 93, "presentReading": 87, "consumption": 65, "dssName": "Zhongfan", "feederName": null, "image": "http://dummyimage.com/141x100.png/5fa2dd/ffffff", "hub": "Lagnieu", "region": "Nové Město pod Smrkem", "serviceCenter": "Blaine", "customerType": "CN", "comment": "Customer cooperated during Enumeration", "createdBy": "Dre Canny", "status": "Sucessful, failed, pending", "dateCreated": "5/13/2023", "readDate": "3/7/2024", "action": "2/14/2024" },
    { "firstName": "Gaétane", "last_name": "Treversh", "accountNo": "c9dbc9e1415484a3d2b7eed1e605d331b55bd8cea541752d78052972ec2b0c3d", "meterNo": "8dd5452acadd17972113572bd1de459e", "tarrif": 59, "previousReading": 37, "presentReading": 85, "consumption": 76, "dssName": "Kurbnesh", "feederName": null, "image": "http://dummyimage.com/104x100.png/cc0000/ffffff", "hub": "Santo Antônio do Amparo", "region": "Xiangxi", "serviceCenter": "Pennsylvania", "customerType": "AL", "comment": "Customer cooperated during Enumeration", "createdBy": "Elvera Treversh", "status": "Sucessful, failed, pending", "dateCreated": "8/13/2023", "readDate": "4/23/2023", "action": "7/4/2023" },
    { "firstName": "Annotés", "last_name": "Wrates", "accountNo": "86b4e8b4a15662668dd8efb2ff2efe853b16763e8eeb74eef9a6d4dadc15ed54", "meterNo": "2286e5f9adf498a3e194731ac1170f63", "tarrif": 13, "previousReading": 9, "presentReading": 49, "consumption": 95, "dssName": "Manizales", "feederName": "170017", "image": "http://dummyimage.com/167x100.png/dddddd/000000", "hub": "Kuala Pilah", "region": "Áno Kopanákion", "serviceCenter": "Clarendon", "customerType": "CO", "comment": "Customer cooperated during Enumeration", "createdBy": "Neils Wrates", "status": "Sucessful, failed, pending", "dateCreated": "6/19/2023", "readDate": "3/26/2024", "action": "4/25/2023" },
    { "firstName": "Frédérique", "last_name": "Strainge", "accountNo": "84a23746ef8674a00324785fd95ba4165fdfabbf06227a57552b0bd897a2ee3b", "meterNo": "1d71696715bded5aebf33e6863c68491", "tarrif": 46, "previousReading": 54, "presentReading": 69, "consumption": 4, "dssName": "Kosmach", "feederName": null, "image": "http://dummyimage.com/206x100.png/5fa2dd/ffffff", "hub": "Oslo", "region": "Mekarsari", "serviceCenter": "Merrick", "customerType": "UA", "comment": "Customer cooperated during Enumeration", "createdBy": "Niki Strainge", "status": "Sucessful, failed, pending", "dateCreated": "9/29/2023", "readDate": "10/4/2023", "action": "7/7/2023" }
];

const hubStatHeader =[
    "Hub",
    "Total Postpaid Customer",
    "Total Metered Customer",
    "Total Meter Read Today",
    "Meter Read",
    "Total Reading Remaining"
];

const hubStat=[
    {
        hub: "Ibadan",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    },
    {
        hub: "Ogun",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    },
    {
        hub: "Osun",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    },
    {
        hub: "Oyo",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    },
    {
        hub: "Kwara",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    },
    {
        hub: "Total",
        totalPostpaidCustomer: "100028734",
        TotalMeteredCustomer: "5126688274",
        TotalMeterReadToday: "100028734",
        MeterRead: "100028734",
        TotalReadingRemaining: "100028734",
      
    }
]
export { tableHeader, tableRows, hubStatHeader, hubStat};
